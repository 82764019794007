import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";

function TopBar() {
  return (
    <Container>
        <Row>
            <Col md={6}>
                <ListGroup horizontal>
                    <ListGroup.Item><a href="tel:3027771140"><FaPhoneAlt/> (307)269-0147</a></ListGroup.Item>
                    <ListGroup.Item><a href="mailto:support@clickcircle.io"><FaEnvelope/> support@clickcircle.io</a></ListGroup.Item>
                </ListGroup>
            </Col>
            <Col md={6}>
                <ListGroup horizontal className='float-end'>
                    <ListGroup.Item>Follow Us :</ListGroup.Item>
                    <ListGroup.Item><FaFacebookF/></ListGroup.Item>
                    <ListGroup.Item><FaTwitter/></ListGroup.Item>
                    <ListGroup.Item><FaInstagram/></ListGroup.Item>
                    <ListGroup.Item><FaLinkedinIn/></ListGroup.Item>
                </ListGroup>
            </Col>
        </Row>
    </Container>
  )
}

export default TopBar