

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { FaHandHoldingUsd } from "@react-icons/all-files/fa/FaHandHoldingUsd";
import { FaRegChartBar } from "@react-icons/all-files/fa/FaRegChartBar";
import { FaChartLine } from "@react-icons/all-files/fa/FaChartLine";
import { FaGlobeAsia } from "@react-icons/all-files/fa/FaGlobeAsia";

function Monetization() {
  return (
    <>
      <Container>
        <Row className='affiliate-row align-items-start' data-aos="fade-up" data-aos-duration="500">
          <Col lg={12} md={12} sm={12}>
            <div className='about-heading'>
              <p className='text-center theme-color benefits'>Our Benefits</p>
              <h2 className='text-center theme-color-2'>Search Monetization Benefits</h2>
            </div>
          </Col>
        </Row>
        <Row className='pods'>
          <Col lg={6} md={6} data-aos="fade-up" data-aos-duration="1000">
            <Card style={{width:"100%", height:"100%"}}>
                <Card.Body>
                  <div className='card-img-extra-paddng text-start'>
                      <FaHandHoldingUsd />
                  </div>
                    <Card.Title className='theme-color-2'>Revenue Estimate</Card.Title>
                    <Card.Text>
                    RPM rates can always be viewed and analyzed on your dashboard. Transparency is what we value the most at ClickCircle
                    </Card.Text>
                </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} data-aos="fade-up" data-aos-duration="1000">
            <Card style={{width:"100%", height:"100%"}}>
                <Card.Body>
                  <div className='card-img-extra-paddng text-start'>
                      <FaRegChartBar />
                  </div>
                    <Card.Title className='theme-color-2'>Direct Feed Search</Card.Title>
                    <Card.Text>
                    ClickCircle has established a completely stable direct feed search monetization strategy with Yahoo, with no intermediaries between you and the Yahoo Search itself.
                    </Card.Text>
                </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} data-aos="fade-up" data-aos-duration="1500">
            <Card style={{width:"100%", height:"100%"}}>
                <Card.Body>
                  <div className='card-img-extra-paddng text-start'>
                      <FaChartLine />
                  </div>
                    <Card.Title className='theme-color-2'>Performance Tracking</Card.Title>
                    <Card.Text>
                    Track your RPM rates and your profit through our real-time analytics.
                    </Card.Text>
                </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} data-aos="fade-up" data-aos-duration="1500">
            <Card style={{width:"100%", height:"100%"}}>
                <Card.Body>
                  <div className='card-img-extra-paddng text-start'>
                      <FaGlobeAsia />
                  </div>
                    <Card.Title className='theme-color-2'>No Boundaries</Card.Title>
                    <Card.Text>
                    As we said before, all types of traffic can be monetized through all GEOs, platforms and extensions.
                    </Card.Text>
                </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Monetization