import { React } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { Link } from "react-router-dom";

// import {  useLocation } from "react-router-dom";

function Footer() {
  
  return (
    <>
      <div className='footer-link'>
        <Container>
          <Row>
              <Col md={12} lg={12} xl={5} className='pl-3'>
                  <h3><Link to="/"><img src="./assets/img/Click-Circle-logo.png" alt='Click-Circle' /></Link></h3>
                  <p className='footer-desc'>
                  Search Monetization Network with high-end technologies and excellent optimization approaches.
                  </p>
                  <ListGroup className=''>
                      <ListGroup.Item className='theme-color-2'>
                          <span className='sm-icons'><FaFacebookF /></span>
                          <span className='sm-icons'><FaTwitter/></span>
                          <span className='sm-icons'><FaInstagram /></span>
                      </ListGroup.Item>
                  </ListGroup>
              </Col>
              <Col lg={4} xl={2} md={4}>
                  <h6>
                  Other Pages
                  </h6>
                  <ul className='link'>
                    <li><Link to="/#about"><FaChevronRight className='footer-sublink'/> About us</Link></li>
                    <li><Link to="/#monetization"><FaChevronRight className='footer-sublink'/> Our Benefits</Link></li>
                  </ul>
              </Col>
              <Col lg={4} xl={2} md={4}>
                  <h6>
                  Quicklinks
                  </h6>
                  <ul className='link'>
                    <li><Link to="/#services"><FaChevronRight className='footer-sublink'/> Why choose us</Link></li>
                    <li><Link to="/#contact"><FaChevronRight className='footer-sublink'/> Contact</Link></li>
                  </ul>
              </Col>
              <Col lg={4} xl={3} md={4}>
                  <h6>
                  Newsletter
                  </h6>
                  <p className=''>Don’t hesitate anymore, join us today and earn a super high commission.</p>
                  <p className='newsletter'><input type="text" name="newsletter" placeholder='Your Email Addres'/></p>
                  <div className='text-start'>
                    <a href="tel:5185172745" onClick={e => e.preventDefault()} className='advertising-btn'>SUBSCRIBE</a>
                </div>
              </Col>
            </Row>
          </Container>
      </div>
    </>
  )
}

export default Footer