import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


function Hero() {
    
  const scrollToElement = (id) => {
    const container = document.getElementById(id.substring(1));
    container.scrollIntoView({ behavior: 'smooth', block: 'start'});
  };
  return (
    <Container  className='heroSection'>
        <Row className="justify-content-md-center align-items-center">
            <Col md={6} lg={6} className='hero-left-col' data-aos="fade-right" data-aos-duration="1000">
                <h1 className='text-lg-start cs-shadow theme-color-2'>The Most Powerful <span className='theme-color'>Traffic Monetization</span> Platform</h1>
                <div className="text-start text-white cs-shadow typetext">
                  <p className='text-black'>Significantly increase your monthly revenue through monetization strategies only ClickCircle can provide. Traffic Monetization has never been easier. Seize that opportunity!</p>
                </div>
                <div className='text-start'>
                <Button variant="outline-primary"  data-href="#about" className='btn-cs'  size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Contact Us</Button>
                </div>
            </Col>
            <Col md={6} lg={6} data-aos="fade-left" data-aos-duration="1000">
              <div className='solo-img'><img src='./assets/img/Asset-4.png' alt="A Performance Focused Global Advertising Company"/></div>
            </Col>
        </Row>
    </Container >
    
  )
}

export default Hero