
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


function CompanyTogether() {
    
  const scrollToElement = (id) => {
    const container = document.getElementById(id.substring(1));
    container.scrollIntoView({ behavior: 'smooth', block: 'start'});
  };
    return (
        
    <Container>
        <Row>
            <Col md={12}>
                <div className='ct-content'>
                    <h2 className='text-whtie text-center' data-aos="zoom-in" data-aos-duration="500">Let's run and grow this company together</h2>
                    <p className='text-center'  data-aos="fade-up" data-aos-duration="500">
                        At Click Circle we don’t simply write code or do online marketing – we forge bonds and do our best to make a mark in the industry. Join our ever-growing team and make an impact on hundreds of millions of users worldwide.
                    </p>
                    
                    <div className='text-center' data-aos="fade-up" data-aos-duration="800">
                        <Button variant="outline-primary"  data-href="#about" className='btn-cs'  size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Contact Us</Button>
                    </div>
                </div>
            </Col>
        </Row>

    </Container>
    )
}


export default CompanyTogether