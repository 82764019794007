import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function About() {
  return (
    
    <Container>
        <Row className='advertising justify-center-content align-items-center' id='advertising'>
            <Col md={6} data-aos="fade-right" data-aos-duration="500">
                <p><img src="./assets/img/Asset-3.png" alt="Connect With the Right Audience When They are Ready to Act " /></p>
            </Col>
            <Col md={6} className='text-content' data-aos="fade-left" data-aos-duration="500">
                <p className='theme-color about-subheading-heading'>About Us</p>
                <h2 className='theme-color-2'>We Help You To Get Best Business Solution Ever</h2>
                <p className='text-black sub-heading'>One of the most comprehensive Monetization Strategies available to affiliates worldwide. Click Circle allows the end-user to monetize online traffic furthermore through a publishing platform in the form of RTB, Native Ads, Search Queries and other ad formats. We ensure that our clients have higher ROI and ad revenue.</p>
                
            </Col>
        </Row>
    </Container>
    
  )
}

export default About