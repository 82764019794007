import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaUserFriends } from "@react-icons/all-files/fa/FaUserFriends";
import { FaHandshake } from "@react-icons/all-files/fa/FaHandshake";
import { FaUpload } from "@react-icons/all-files/fa/FaUpload";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import AnimatedNumber from "animated-number-react";


function Statistical() {
  return (
    <Container>
    <Row>
        <Col md={12}>
            <h2 className='theme-color-2 text-center'  data-aos="fade-up" data-aos-duration="500">Statistical growth</h2>
        </Col>
    </Row>
      <Row className='choose-map'>
        <Col md={6} lg={3} sm={6} xs={12} data-aos="fade-down" data-aos-duration="500">
          <p className='theme-color text-center'><FaUserFriends /></p>
          <h2 className='text-center'> 
            <AnimatedNumber value={5} formatValue={(value) => value.toFixed(0)} /> </h2>
          <p className='text-center'>Years Of Experience</p>
        </Col>
        <Col md={6} lg={3} sm={6} xs={12} data-aos="fade-up" data-aos-duration="500">
          <p className='theme-color text-center'><FaHandshake /></p>
          <h2 className='text-center'> 
            <AnimatedNumber value={300} formatValue={(value) => value.toFixed(0)} /> +</h2>
          <p className='text-center'>Satisfied Customers</p>
        </Col>
        <Col md={6} lg={3} sm={6} xs={12} data-aos="fade-down" data-aos-duration="500">
          <p className='theme-color text-center'><FaGlobe /></p>
          <h2 className='text-center'> 
            <AnimatedNumber value={13} formatValue={(value) => value.toFixed(0)} />M </h2>
          <p className='text-center'>Ad Request Per Day</p>
        </Col>
        <Col md={6} lg={3} sm={6} xs={12} data-aos="fade-up" data-aos-duration="500">
          <p className='theme-color text-center'><FaUpload /></p>
          <h2 className='text-center'> 
            <AnimatedNumber value={15} formatValue={(value) => value.toFixed(0)} /> +</h2>
          <p className='text-center'>Countries served</p>
        </Col>
      </Row>
    </Container >
    
  )
}

export default Statistical