import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaMapMarked } from "@react-icons/all-files/fa/FaMapMarked";
import { FaPhoneSquareAlt } from "@react-icons/all-files/fa/FaPhoneSquareAlt";
import { FaEnvelopeOpen } from "@react-icons/all-files/fa/FaEnvelopeOpen";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function KeepTouch() {
  return (
    <Container>
      <Row>
        <Col md={6} data-aos="fade-right" data-aos-duration="1000">
            <div>
              
              <ListGroup>
                    <ListGroup.Item>
                        <FaMapMarked />
                        <div>
                            <p>1309 Coffeen Avenue STE 8652,Sheridan,Wyoming 82801</p>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <FaPhoneSquareAlt />
                        <div>
                            <p><a href="tel:3027771140" className='theme-color'>(307)269-0147</a></p>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <FaEnvelopeOpen />
                        <div>
                            <p><a href='mailto:support@clickcircle.io' className='theme-color'>support@clickcircle.io</a></p>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
              <p className='theme-color'><strong>WE'D LOVE TO HEAR FROM YOU</strong></p>
              <h2 className='text-white'>Contact us now</h2>
              <p className='keep-desc'>Got questions? No worries! Just send us a message and we will respond as soon as possible.</p>
              <p className='keep-desc-2'>REACH US THROUGH</p>
            </div>
        </Col>
        <Col md={6} data-aos="fade-left" data-aos-duration="1000">
            <h2 className='send-us'>Send Us A Message</h2>
          <Form onSubmit={e=>e.preventDefault()} className='form-static'>
            <Row>
                <Col md={12}>
                <Form.Control placeholder="Your Name" name="name" type="text" />
                </Col>
                <Col md={12}>
                <Form.Control placeholder="Email Address" name="email" type="email" />
                </Col>
                <Col md={12}>
                <Form.Control placeholder="Phone Number" name="email" type="text" />
                </Col>
                <Col md={12}>
                </Col>
                <Col md={12}>
                    <Form.Control
                    as="textarea"
                    placeholder="Message"
                    style={{ height: '100px' }}
                    />
                </Col>
                
                <Col md={12} className='text-center'>
                    <Button variant="primary" type="submit" className='mt-2 cs-btn-form'>
                        SEND MESSAGE
                    </Button>
                </Col>
            </Row>
        </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default KeepTouch