import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import Button from 'react-bootstrap/Button';

function Publisher() {
    
    const scrollToElement = (id) => {
      const container = document.getElementById(id.substring(1));
      container.scrollIntoView({ behavior: 'smooth', block: 'start'});
    };
  return (
    <Container>
        <Row>
            <Col md={6} data-aos="fade-right" data-aos-duration="1000">
                <p className='theme-color'>Why Us</p>
                <h2 className='theme-color-2'>Search Query Monetization</h2>
                <p>Choose whether to Monetize your Transactional, Navigational or Informational Search Queries. Yahoo Search Monetization, Bing Search Monetization or your own. It’s completely up to you!</p>
                <div className='col2-list'>
                    <ListGroup>
                        <ListGroup.Item>
                            <FaCheckCircle />
                            <div>
                                <p>Direct Search Feed</p>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FaCheckCircle />
                            <div>
                                <p>App search traffic</p>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FaCheckCircle />
                            <div>
                                <p>Search Yahoo Monetization</p>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                    <ListGroup>
                        <ListGroup.Item>
                            <FaCheckCircle />
                            <div>
                                <p>Extension Traffic</p>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FaCheckCircle />
                            <div>
                                <p>In App traffic</p>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FaCheckCircle />
                            <div>
                                <p>Search Bing Monetization</p>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </div>


                <div className='text-start'>
                    <Button variant="outline-primary"  data-href="#about" className='btn-cs'  size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Contact Us</Button>
                </div>
            </Col>
            <Col md={6} data-aos="zoom-in" data-aos-duration="500">
                <p><img src="./assets/img/Asset-5-1.png" alt="Search Query Monetization"/></p>
            </Col>
        </Row>
    </Container>
  )
}

export default Publisher