

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Affiliate() {
  return (
    <>
      <Container>
        <Row className='affiliate-row align-items-start'>
          <Col md={6} lg={3} sm={12}  data-aos="fade-down" data-aos-duration="1000">
            <div className='hover-box'>
              <img src="./assets/img/bing@2x-150x150.png" alt="RPM:80$ in the US" />
              <div className='about-heading'>
                <div>
                <h2 className='text-center theme-color-2'>RPM:80$ in the US</h2>
                <p className='text-center text-gray'>Bing is a web search engine launched by Microsoft</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3} sm={12} data-aos="fade-up" data-aos-duration="1000">
            <div className='hover-box'>
              <img src="./assets/img/yahoo@2x-150x150.png" alt="RPM:70$ in the US" />
              <div className='about-heading'>
                <div>
                <h2 className='text-center theme-color-2'>RPM:70$ in the US</h2>
                <p className='text-center text-gray'>Yahoo Search is an Internet search engine operated by Verizon Media</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3} sm={12} data-aos="fade-down" data-aos-duration="1000">
            <div className='hover-box'>
              <img src="./assets/img/baidu@2x-150x150.png" alt="RPM:10$ in China" />
              <div className='about-heading'>
                <div>
                <h2 className='text-center theme-color-2'>RPM:10$ in China</h2>
                <p className='text-center text-gray'>Baidu is China's largest search engine service provider</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3} sm={12} data-aos="fade-up" data-aos-duration="1000">
            <div className='hover-box'>
              <img src="./assets/img/yandex@2x-150x150.png" alt="RPM:20$ in Russia" />
              <div className='about-heading'>
                <div>
                <h2 className='text-center theme-color-2'>RPM:20$ in Russia</h2>
                <p className='text-center text-gray'>Yandex's search engine has over 60% market share in Russia</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Affiliate