import { useEffect, React, useState, useRef } from 'react';
import './assets/css/Reset.css';
import Header from './component/Header';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from "react-scroll-to-top";
import Footer from './component/Footer';
import Copyright from './component/Copyright';

import { Routes, Route, useLocation } from "react-router-dom";
import Home from './component/Home';
import TopBar from './component/TopBar';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -100,
  });

  const [scroll, setScroll] = useState(false);
  let location = useLocation();
  useEffect(() => {
    
    AOS.init({
      offset: 150,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
      disable: 'mobile'
    });
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 200);
    });
    if (location.hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = location.hash.toString().replace('#', '');
        // console.log(location.hash);
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [location]);
  return (
    <div className="App">
      <div className='top-bar'><TopBar /></div>
      <header className={scroll ? "App-header bg-scroll sticky-top" : "App-header "}>
        <Header activeSection={activeSection} />
      </header>
      <main>
        <Routes>
            <Route index path="/" element={<Home sectionRefs={sectionRefs} />} />
        </Routes>
      </main>
      <footer>
        <div className="elementor-shape elementor-shape-top" data-negative="false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
        <path className="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
        <path className="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
        <path className="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
        </svg>		</div>
        <div className='f-col'><Footer /></div>   
        <div className='copyright'><Copyright/></div>
      </footer>
      <ScrollToTop smooth color="#fff" className='text-center'/>
    </div>
  );
}

export default App;
